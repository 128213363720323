#nav-wrap ul, #nav-wrap li, #nav-wrap a {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

#nav-wrap {
    background-color: #444;
    font: 12px 'opensans-bold', sans-serif;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin: 0 auto;
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
}

ul#nav {
  min-height: 48px;
  width: auto;
  text-align: center;
  align-items: stretch;
}

ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
}

ul#nav li a {
  display: inline-block;
  padding: 8px 13px;
  line-height: 32px;
   text-decoration: none;
  text-align: left;
  color: #fff;
}
